import React, { useState, useContext, toUpperCase } from "react";
import GetAveScores from "./GetAveScores";
import GetScores from "./GetScores";
import AddNewTag from "./AddNewTag";
import { TagContext } from "../store/TagContext"; 
import "../scss/SearchByName.scss";

export const SearchByName = (props) => {
  const [searchField, setSearchField] = useState("");
  const [searchTagField, setSearchTagField] = useState("");
  const [tags, setTags] = useContext(TagContext);

  const isTagExist = tags[searchTagField] !== undefined;
  const taggedStudentIds = isTagExist ? tags[searchTagField] : [];

  let filteredUsers = props.students;

  if (searchField !== "") {
    filteredUsers = props.students.filter((student) => {
      const fullName = student.firstName.concat(student.lastName);
      return fullName.toUpperCase().includes(searchField.toUpperCase());
    });
  }

  if (searchTagField !== "") {
    filteredUsers = filteredUsers.filter((student) => {
      return taggedStudentIds.includes(student.id);
    });
  }

  console.log("filteredUsers:", filteredUsers);

  return (
    <React.Fragment>
      <input
        className="searchBoxStyle"
        type="search"
        placeholder="Search by name"
        onChange={(e) => setSearchField(e.target.value)}
      />
      <br />

      <input
        className="searchBoxStyle2"
        type="search"
        placeholder="Search by tag"
        onChange={(e) => setSearchTagField(e.target.value)}
      />
      <div className="cardWrap">
        {filteredUsers.map((student, idx) => {
          return (
            <div className="card" key={idx}>
              <img className="studentPhoto" alt="student" src={student.pic} />
              <div className="studentInformationContainer">
                <h1 className="studentName">
                  {student.firstName.toUpperCase()}&nbsp;
                  {student.lastName.toUpperCase()}
                </h1>
                <div>Email: {student.email}</div>
                <div>Company:{student.company}</div>
                <div>Skill:{student.skill}</div>
                <div>Average Score</div>
                <GetAveScores grades={student.grades} />
                <GetScores grades={student.grades} />
                <AddNewTag student={student} />
              </div>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};
