import React, { useState, useContext } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";
import styled from "styled-components";

const DropdownContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
`;

const ExpendableButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 2.5rem;
  cursor: pointer;
  color: grey;
  background-color: #fff;
  border: 0;
  &:hover {
    color: #000;
  }
`;

const DropdownWrapper = styled.div`
  display: ${({ isOpen }) => (isOpen ? "contents" : "none")};
`;

const TestScore = styled.span`
  color: grey;
`;
const GetScores = (score) => {
  const [isOpen, setIsOpen] = useState(false); // EXPANDABLEBUTTON STATE
  const [isOpenText, setIsOpenText] = useState(FaPlus); // HTML ICON STATE

  const toggle = () => {
    if (!isOpen) {
      console.log("isOpen");
      setIsOpen(true);
      setIsOpenText(FaMinus);
    } else {
      console.log("!isOpen");
      setIsOpen(false);
      setIsOpenText(FaPlus);
    }
  };

  return (
    <DropdownContainer>
      <ExpendableButton value="button" onClick={toggle}>
        {isOpenText}
      </ExpendableButton>
      <DropdownWrapper isOpen={isOpen}>
        {score.grades.map((grade, idx) => {
          return (
            <TestScore key={idx}>
              Test {idx + 1}: {grade}%
            </TestScore>
          );
        })}
      </DropdownWrapper>
    </DropdownContainer>
  );
};

export default GetScores;
