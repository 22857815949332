import React, { useState, useContext } from "react";
import styled from "styled-components";
import { TagContext } from "../store/TagContext"; // useConext

const InputBox = styled.input`
  border: 0;
  border-bottom: 1px solid #e6e3e4;
  color: #000;
  margin-top: 20px;
  box-sizing: border-box;
  &:focus {
    outline: none;
    border-bottom: 1px solid #000;
  }
`;

const AddedTagCss = styled.div`
  background-color: #d7dbdb;
  width: 60px;
  max-width: 200px;
  overflow: hidden;
  height: ${({ inputField }) =>
    inputField ? "40px;" : "0px;"}; // 배열에 값이 없을 경우, 배경 없애기
  margin: 2px 2px 3px;
  border-radius: 5px;
  align-content: center;
  justify-content: center;
  display: flex;
  line-height: 40px;
`;

const TagsCss = styled.div`
  display: flex;
`;

const AddNewTag = (props) => {
  const [inputField, setInputField] = useState([]);
  const [tags, setTags] = useContext(TagContext);

  // Enter Key pressed with valid value?
  const enterKeyPress = (e) => {
    if (e.target.value === "") {
      return;
    } else if (e.key === "Enter") {
      setInputField("");
      addTag(e.target.value, props.student.id);
      e.target.value = "";
    } else {
      return;
    }
  };

  // Create the new Tag
  const addTag = (tagName, studentId) => {
    let students = tags[tagName]; // newTags에 담긴 tagName의 오브젝트를 가져온다.
    if (students === undefined) {
      students = [studentId];
    } else {
      console.log(typeof students);
      if (students.includes(studentId) === false) {
        students.push(studentId);
      }
    }

    console.log("tagName:", tagName);
    console.log("studentId:", studentId);
    console.log("tags", tags);
    // setTags(newTags);
    setTags({ ...tags, [tagName]: students });
  };

  const studentTags = Object.keys(tags).reduce((filtered, key) => {
    if (tags[key].includes(props.student.id)) {
      filtered.push(key);
    }
    return filtered;
  }, []);

  return (
    <React.Fragment>
      <TagsCss>
        {studentTags.map((tag, idx) => (
          <AddedTagCss inputField={tag.length} key={idx}>
            {tag}
          </AddedTagCss>
        ))}
        {/* <AddedTagCss inputField={tags.length}>{tags}</AddedTagCss> */}
      </TagsCss>
      <InputBox
        type="text"
        placeholder="Add a tag"
        onChange={(e) => setInputField(e.target.value)}
        value={inputField.value}
        onKeyPress={enterKeyPress}
      />
    </React.Fragment>
  );
};

export default AddNewTag;
