import StudentInformation from "./components/StudentInformation";
import { UserProvider } from "./store/UserContext";
import { TagProvider } from "./store/TagContext";

function App() {
  return (
    <UserProvider>
      <TagProvider>
        <StudentInformation />
      </TagProvider>
    </UserProvider>
  );
}

export default App;
