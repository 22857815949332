import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { UserContext } from "../store/UserContext"; // Import UserContext
import styled from "styled-components";
import { SearchByName } from "./SearchByName";

const MainContainer = styled.div`
  display: block;
  margin-top: 30px;
  height: 800px;
  width: 940px;
  margin: 10px auto;
  padding: 20px;
  position: relative;
  justify-content: center;

  @media screen and (min-width: 560px) and (max-width: 940px) {
    width: 640px;
  }
  @media screen and (min-width: 380px) and (max-width: 680px) {
    width: 440px;
  }
  @media screen and (min-width: 280px) and (max-width: 510px) {
    width: 340px;
  }
`;

const StudentInformation = () => {
  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [students, setStudents] = useContext(UserContext);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        // INITIALIZATION ERROR, USERS
        setError(null);
        setUsers(null);
        // INITIALIZATION LOADING STATUS
        setLoading(true);
        const response = await axios.get(
          "https://api.hatchways.io/assessment/students"
        );
        setUsers(response.data);
        setStudents(response.data); // USERCONTEXT
      } catch (e) {
        console.log("ERROR: Something wrong. please checking the code.");
        setError(e);
      }
      setLoading(false);
    };

    fetchUsers();
  }, []);

  if (loading) return <div>Loading..</div>;
  if (error) return <div>Something wrong. Please retry.</div>;
  if (!users) return null;

  console.log(students.students);

  return (
    <MainContainer>
      <SearchByName students={students.students} />
    </MainContainer>
  );
};

export default StudentInformation;
