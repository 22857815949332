import React, { createContext, useState } from "react";

export const UserContext = createContext();

export const UserProvider = (props) => {
  const [students, setStudents] = useState([{}]);

  return (
    <UserContext.Provider value={[students, setStudents]}>
      {props.children}
    </UserContext.Provider>
  );
};
export default UserContext;
