import React from "react";
import styled from "styled-components";

const GetAveScoresPack = styled.span``;

const GetAveScores = (score) => {
  // CONVERT STRING => NUMBER
  const mathScore = score.grades.map((grades, idx) => {
    return parseInt(grades);
  });

  // CALCULATE SUM
  const result = mathScore.reduce(function add(sum, currValue) {
    return sum + currValue;
  }, 0);

  // CALCULATE AVERAGE
  const findAveScores = result / mathScore.length;
  return <GetAveScoresPack>Average: {findAveScores}%</GetAveScoresPack>;
};

export default GetAveScores;
